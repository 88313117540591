import axios from "axios";
import authHeader from "./auth-header";
import { API_ROUTES } from "../config/apiRoutes.config";
import { config } from "../config/env.config"

const getAll = () => {
  return axios.get(
    `${config.api.baseUrl}/${API_ROUTES.LOGBOOK}`,
    { headers: authHeader() }
  );
}

const get = id => {
  return axios.get(
    `${config.api.baseUrl}/${API_ROUTES.LOGBOOK}/${id}`,
    { headers: authHeader() }
    );
}

const create = data => {
  return axios.post(
    `${config.api.baseUrl}/${API_ROUTES.LOGBOOK}`, 
    data,
    { headers: authHeader() }
  );
}

const update = (id, data) => {
  return axios.put(
    `${config.api.baseUrl}/${API_ROUTES.LOGBOOK}/${id}`, 
    data,
    { headers: authHeader() }
  );
}

const remove = id => {
  return axios.delete(
    `${config.api.baseUrl}/${API_ROUTES.LOGBOOK}/${id}`,
    { headers: authHeader() }
  );
}

const removeAll = () => {
  return axios.delete(
    `${config.api.baseUrl}/${API_ROUTES.LOGBOOK}`,
    { headers: authHeader() }
  );
}

const findByTitle = title => {
  return axios.get(
    `${config.api.baseUrl}/${API_ROUTES.LOGBOOK}?title=${title}`,
    { headers: authHeader() }
  );
}

const fullTextSearch = searchString => {
  return axios.get(
    `${config.api.baseUrl}/${API_ROUTES.LOGBOOK}/search?searchString=${searchString}`,
    { headers: authHeader() }
  )
} 

export default {
  getAll,
  get,
  create,
  update,
  remove,
  removeAll,
  findByTitle,
  fullTextSearch,
};