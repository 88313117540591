import React, { useState, useEffect, useRef } from "react";
import LogbookService from "../services/logbook.service";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";

const AddLog = () => {
  const initialLogState = {
    id: null,
    title: "",
    startTime: "",
    endTime: "",
    entry: "",
  };

  const [log, setLog] = useState(initialLogState);
  const [submitted, setSubmitted] = useState(false);
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [logTitle, setLogTitle] = useState(null);
  const [logTitleFlag, setLogTitleFlag] = useState(false);
  const isFirstRender = useRef(true);

  // retrieving LogBook from api
  const retrieveLogbook = () => {
    LogbookService.getAll()
      .then((res) => {
        let arr = res.data;
        arr.sort((a, b) => new Date(b.startTime) - new Date(a.startTime));
        setLogTitle("Log " + (Number(arr[0].title.split(" ")[1]) + 1));
        setLogTitleFlag(true);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // On first render, call LogBook for title
  useEffect(() => {
    retrieveLogbook();
    isFirstRender.current = false;
  }, []);

  // setting constructed title to Log object
  useEffect(() => {
    if (logTitleFlag) {
      setLog({
        ...log,
        title: logTitle,
      });
      setLogTitleFlag(false);
    }
  }, [logTitleFlag, log, logTitle]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setLog({ ...log, [name]: value });
  };

  const saveLog = () => {
    let data = {
      title: log.title,
      startTime: startTime,
      endTime: endTime,
      entry: log.entry,
    };

    LogbookService.create(data)
      .then((res) => {
        setLog({
          id: res.data.id,
          title: res.data.title,
          startTime: res.data.startTime,
          endTime: res.data.endTime,
          entry: res.data.entry,
        });
        setSubmitted(true);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const newLog = () => {
    retrieveLogbook();
    setLog(initialLogState);
    setStartTime(new Date());
    setEndTime(new Date());
    setSubmitted(false);
  };

  return (
    <div className="container-lg mt-3 pt-3">
      {submitted ? (
        <div className=" ">
          <h4 className="py-4 d-flex justify-content-around">
            The log was submitted successfully.
          </h4>
          <div className="d-flex justify-content-around">
            <button className="btn btn-success" onClick={newLog}>
              Add another log
            </button>
            <Link to={"/logbook"} className="btn btn-info">
              View log book
            </Link>
          </div>
        </div>
      ) : (
        <div>
          <h4 className="py-1">Add new log</h4>
          <div>
            <div className="form-group">
              <label htmlFor="title">Title</label>
              <input
                type="text"
                className="form-control"
                id="title"
                required
                value={log.title}
                onChange={handleInputChange}
                name="title"
              />
            </div>
            <div className="d-flex mb-4">
              <div className="col-md-6">
                <label htmlFor="date">Start Time</label>
                <DatePicker
                  selected={startTime}
                  className="form-control"
                  wrapperClassName="form-control"
                  width="100%"
                  onChange={(date) => setStartTime(date)}
                  name="date"
                  value={startTime}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={5}
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="date">End Time</label>
                <DatePicker
                  selected={endTime}
                  className="form-control"
                  wrapperClassName="form-control"
                  width="100%"
                  onChange={(date) => setEndTime(date)}
                  name="date"
                  value={endTime}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={5}
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="entry">Entry</label>
              <textarea
                type="text"
                rows="10"
                className="form-control"
                id="entry"
                required
                value={log.entry}
                onChange={handleInputChange}
                name="entry"
                placeHolder="Entry"
              />
            </div>
            <button onClick={saveLog} className="btn btn-success mb-4">
              Submit
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddLog;
