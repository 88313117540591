import React, { useState, useEffect } from "react";
import LogbookService from "../services/logbook.service";
import DatePicker from "react-datepicker";
import { Link, withRouter } from "react-router-dom";

const EditLog = (props) => {
  const { id } = props.computedMatch.params;
  const initialLogState = {
    id: null,
    title: "",
    startTime: "",
    endTime: "",
    entry: "",
  };

  const [log, setLog] = useState(initialLogState);
  const [message, setMessage] = useState("");
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  // const isFirstRender = useRef(true);

  const getLog = (id) => {
    LogbookService.get(id)
      .then((res) => {
        let dbStartTime = new Date(res.data.startTime);
        let dbEndTime = new Date(res.data.endTime);
        setLog({
          ...res.data,
          startTime: dbStartTime,
          endTime: dbEndTime,
        });
        setStartTime(dbStartTime);
        setEndTime(dbEndTime);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getLog(id);
    // isFirstRender.current = false
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setLog({ ...log, [name]: value });
  };

  const handleStartTimeChange = (startTime) => {
    setStartTime(startTime);
    setLog({
      ...log,
      startTime: startTime,
    });
  };

  const handleEndTimeChange = (endTime) => {
    setStartTime(endTime);
    setLog({
      ...log,
      endTime: endTime,
    });
  };

  const updateLog = () => {
    LogbookService.update(log.id, log)
      .then((res) => {
        setMessage("The log was updated successfully.");
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const deleteLog = () => {
    LogbookService.remove(log.id)
      .then((res) => {
        props.history.push("/logbook");
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div className="container-lg my-3 pt-3">
      {log ? (
        <div className="">
          <h4 className="py-1">Edit log</h4>
          <form>
            <div className="form-group">
              <label htmlFor="title">Title</label>
              <input
                type="text"
                className="form-control"
                id="title"
                name="title"
                value={log.title}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <div className="col-md-6">
                <label htmlFor="date">Start Time</label>
                <DatePicker
                  selected={startTime}
                  className="form-control"
                  wrapperClassName="form-control"
                  width="100%"
                  onChange={(date) => handleStartTimeChange(date)}
                  name="date"
                  value={startTime}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={5}
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="date">End Time</label>
                <DatePicker
                  selected={endTime}
                  className="form-control"
                  wrapperClassName="form-control"
                  width="100%"
                  onChange={(date) => handleEndTimeChange(date)}
                  name="date"
                  value={endTime}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={5}
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="entry">Entry</label>
              <textarea
                type="text"
                rows="10"
                className="form-control"
                id="entry"
                name="entry"
                value={log.entry}
                onChange={handleInputChange}
              />
            </div>
          </form>
          <div className="mt-3">
            <p>{message}</p>
          </div>
          <div className="mb-3">
            <button
              type="submit"
              className="btn btn-success mr-2"
              onClick={updateLog}
            >
              Update
            </button>
            <button className="btn btn-warning mx-2" onClick={deleteLog}>
              Delete
            </button>
            <Link to={"/logbook"} className="btn btn-info mx-2">
              Home
            </Link>
            <Link to={"/add"} className="btn btn-light mx-2">
              Add New Log
            </Link>
          </div>
        </div>
      ) : (
        <div>
          <br />
          <p>Please click on a log...</p>
        </div>
      )}
    </div>
  );
};

export default withRouter(EditLog);
