import React, { useState, useEffect, useRef } from "react";
import LogbookService from "../services/logbook.service";

const Timer = () => {
  const initialLogState = {
    title: "",
    elapsedTime: "",
    event: "Feeding",
    entry: "",
  };

  const [log, setLog] = useState(initialLogState);
  const [logTitle, setLogTitle] = useState(null);
  const [logTitleFlag, setLogTitleFlag] = useState(false);
  const isFirstRender = useRef(true);
  const [isActive, setIsActive] = useState(false);
  const [isPaused, setIsPaused] = useState(true);
  const [time, setTime] = useState(0);

  // retrieving LogBook from api
  const retrieveLogbook = () => {
    LogbookService.getAll()
      .then((res) => {
        let arr = res.data;
        arr.sort((a, b) => new Date(b.startTime) - new Date(a.startTime));
        setLogTitle("Log " + (Number(arr[0].title.split(" ")[1]) + 1));
        setLogTitleFlag(true);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // On first render, call LogBook for title
  useEffect(() => {
    retrieveLogbook();
    isFirstRender.current = false;
  }, []);

  // setting constructed title to Log object
  useEffect(() => {
    if (logTitleFlag) {
      setLog({
        ...log,
        title: logTitle,
      });
      setLogTitleFlag(false);
    }
  }, [logTitleFlag, log, logTitle]);

  useEffect(() => {
    let interval = null;

    if (isActive && isPaused === false) {
      interval = setInterval(() => {
        setTime((time) => time + 10);
      }, 10);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isActive, isPaused]);

  const handleStart = () => {
    setIsActive(true);
    setIsPaused(false);
  };

  const handlePauseResume = () => {
    setIsPaused(!isPaused);
  };

  const handleReset = () => {
    setIsActive(false);
    setTime(0);
    setLog(initialLogState);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLog({
      ...log,
      [name]: value,
    });
  };

  const handleEventSelection = (e) => {
    setLog({
      ...log,
      event: e.target.value,
    });
  };

  const saveLog = () => {
    // time is in milliseconds
    let data = {
      title: log.title,
      startTime: new Date(),
      endTime: new Date(),
      elapsedTime: time,
      event: log.event,
      entry: log.entry,
    };

    LogbookService.create(data)
      .then((res) => {
        setLog({
          title: res.data.title,
          elapsedTime: res.data.elapsedTime,
          event: res.data.event,
          entry: res.data.entry,
        });
        handleReset();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // Control Buttons
  const StartButton = (
    <div className="btn-grp col-12">
      <div className="btn btn-grp btn-info mx-auto" onClick={handleStart}>
        Start
      </div>
    </div>
  );

  const ActiveButtons = (
    <>
      <div className="btn-grp col-12 ">
        <div
          className="btn btn-grp btn-secondary mr-2 col-6"
          onClick={handleReset}
        >
          Reset
        </div>
        <div
          className="btn btn-grp btn-info ml-2 col-6"
          onClick={handlePauseResume}
        >
          {isPaused ? "Resume" : "Stop"}
        </div>
      </div>
      <div className="btn-grp col-12">
        {isPaused && (
          <div
            className="btn btn-grp btn-success my-4 col-12"
            onClick={saveLog}
          >
            Save
          </div>
        )}
      </div>
    </>
  );

  return (
    <div className="d-flex flex-column justify-content-around col-md-6 container mt-3 pt-3">
      <div className="timer">
        <span className="digits" aria-label="hours">
          {("0" + Math.floor((time / 360000) % 60)).slice(-2)}:
        </span>
        <span className="digits" aria-label="minutes">
          {("0" + Math.floor((time / 60000) % 60)).slice(-2)}:
        </span>
        <span className="digits" aria-label="seconds">
          {("0" + Math.floor((time / 1000) % 60)).slice(-2)}
        </span>
      </div>
      <div>
        <select
          className="form-control"
          id="selectBox"
          value={log.event}
          onChange={handleEventSelection}
        >
          <option value="Feeding" name="event">
            Feeding
          </option>
          <option value="Sleep" name="event">
            Sleep
          </option>
          <option value="Freetime" name="event">
            Free Time
          </option>
        </select>
      </div>
      <div className="form-group py-4" aria-label="entry">
        <textarea
          type="text"
          rows="3"
          className="form-control"
          id="entry"
          value={log.entry}
          onChange={handleInputChange}
          name="entry"
          placeholder="Notes"
        />
      </div>
      <div className="Control-Buttons">
        <div>{isActive ? ActiveButtons : StartButton}</div>
      </div>
    </div>
  );
};

export default Timer;
