import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import AuthService from "./services/auth.service";

import ProtectedRoute from "./components/ProtectedRoute";

import Login from "./components/Login";
import Register from "./components/Register";
import Home from "./components/Home";
import Profile from "./components/Profile";
import Timer from "./components/Timer";
import AddLog from "./components/AddLog";
import EditLog from "./components/EditLog";
import Logbook from "./components/Logbook";
// import MedsList from './components/MedsList'
// import Dashboard from './components/Dashboard'

const App = () => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    if (user) {
      setCurrentUser(user);
    } else {
      setCurrentUser(null);
    }
  }, []);

  const logOut = () => {
    AuthService.logout();
  };

  const toTitleCase = (str) => {
    return str.replace(
      /\w\S*/g,
      (txt) => str.charAt(0).toUpperCase() + str.substr(1).toLowerCase()
    );
  };

  return (
    <Router>
      <div>
        <nav className="navbar navbar-expand navbar-dark bg-dark ">
          <a href="/home" className="navbar-brand">
            Grow
          </a>
          <div className="navbar-nav mr-auto">
            {/* <li className="nav-item">
              <Link to={"/home"} className="nav-link">
                Home
              </Link>
            </li> */}

            {currentUser && (
              <>
                <li className="nav-item">
                  <Link to={"/logbook"} className="nav-link">
                    Logs
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to={"/add"} className="nav-link">
                    Add
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to={"/timer"} className="nav-link">
                    Timer
                  </Link>
                </li>
                {/* <li className='nav-item'>
                  <Link to={'/medslist'} className='nav-link'>
                    Meds
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link to={'/dashboard'} className='nav-link'>
                    Dash
                  </Link>
                </li> */}
              </>
            )}
          </div>

          {currentUser ? (
            <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to={"/profile"} className="nav-link">
                  {toTitleCase(currentUser.username)}
                </Link>
              </li>
              <li className="nav-item">
                <a href="/login" className="nav-link" onClick={logOut}>
                  LogOut
                </a>
              </li>
            </div>
          ) : (
            <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to={"/login"} className="nav-link">
                  Login
                </Link>
              </li>

              <li className="nav-item">
                <Link to={"/register"} className="nav-link">
                  Sign Up
                </Link>
              </li>
            </div>
          )}
        </nav>

        <div className="container mt-0">
          <Switch>
            <Route exact path={["/home"]} component={Home} />
            <Route exact path={["/", "/login"]} component={Login} />
            <Route exact path="/logout" component={logOut} />
            <Route exact path="/register" component={Register} />
            <ProtectedRoute exact path="/profile" component={Profile} />
            <ProtectedRoute exact path="/timer" component={Timer} />
            <ProtectedRoute exact path={["/logbook"]} component={Logbook} />
            <ProtectedRoute exact path={["/add"]} component={AddLog} />
            <ProtectedRoute path="/logbook/:id" component={EditLog} />
            {/* <ProtectedRoute exact path={['/dashboard']} component={Dashboard} /> */}
            {/* <ProtectedRoute path='/medslist' component={MedsList} /> */}
          </Switch>
        </div>
        {/* <nav className="navbar navbar-expand navbar-dark bg-dark mt-5">
          <p></p>
        </nav> */}
      </div>
    </Router>
  );
};

export default App;
