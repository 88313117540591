const toApiEndpoint = endpoint => `api/${endpoint}`;

export const API_ROUTES = Object.freeze({
  AUTH: toApiEndpoint("auth"),
  IS_LOGGED_IN: toApiEndpoint("isLoggedIn"),
  LOGIN: toApiEndpoint("login"),
  UNAUTHORIZED_REDIRECT: toApiEndpoint("unauthorizedRedirect"),
  LOGBOOK: toApiEndpoint("logbook"),
  MEDSLIST: toApiEndpoint("medslist"),
  TEST: toApiEndpoint("test")
});
