import React from "react";


const Home = () => {

  return (
    // <div className="d-flex h-100% align-items-center justify-content-center">
      <div className="home-cover ">
        <h3 className="">Grow</h3>
      </div>
    // </div>
  );
};

export default Home;