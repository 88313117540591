//helper function to add auth header to requests
export default function authHeader() {
  const user = JSON.parse(localStorage.getItem('user'));

  if (user && user.accessToken) {
    // for Node.js Express back-end
    return { 
      'x-access-token': user.accessToken,
      "Content-type": "application/json"
    };
  } else {
    return {};
  }
}